































































































































































import Vue from 'vue'
import {Component, PropSync} from 'vue-property-decorator'
import {userNamespace} from '@store/user'
import {documentsNamespace} from '@store/documents'
import {DocumentsActionTypes} from '@store/documents/Types'
import {IDocumentTypes} from '@store/documents/Interface'
import {IProject, IUser} from '@store/user/Interface'
import {ICode, IMark} from '@store/common/Interface'

import {commonNamespace} from '@store/common'
import {ElForm} from 'element-ui/types/form'
import {CommonActionTypes} from '@/store/common/Types'
import {RequestParams} from '@/store/interfaces'

interface Form {
  code: string | number
  markId: number | null
  typeId: number | null
  files: File[]
}

@Component({
  name: 'DialogAddDocument',
  components: {
    'v-ecp-icon': () => import('@/views/library/components/EcpIcon.vue'),
    'v-delete-icon': () => import('@/views/library/components/DeleteIcon.vue'),
    'v-ecp-form-modal': () => import('@/views/library/components/EcpFormModalForNewFiles.vue'),
  }
})
export default class DialogAddDocumentComponent extends Vue {
  @PropSync('visible', Boolean) private _visible!: boolean

  // @Prop({ default: () => null }) private doc!: IDocument

  // @Prop({ default: () => null }) private category!: IDocumentTypes

  @commonNamespace.State('marks') private marks!: IMark[]

  @commonNamespace.State('codes') private codes!: ICode[]

  @userNamespace.State('selectedProject')
  private selectedProjectId!: number

  @userNamespace.Getter('project')
  private selectedProject!: IProject

  @userNamespace.State('user') public user!: IUser;

  @documentsNamespace.State('documentTypes')
  private documentTypes!: IDocumentTypes[]

  @documentsNamespace.Action(DocumentsActionTypes.A_DOCUMENTS_UPLOAD)
  private documentUpload!: (params) => Promise<any>

  @documentsNamespace.Action(DocumentsActionTypes.A_SIGN_UPLOAD)
  private signUpload!: (params:{sign:string,user_id:number,document_id:number}) => Promise<any>

  @commonNamespace.Action(CommonActionTypes.A_CODES)
  private fetchCodes!: (params: RequestParams) => Promise<ICode[]>

  private showEcpModal: boolean = false

  private openEcpModal(file) {
    this.fileForSign = file
    this.showEcpModal = true
  }

  private fileForSign: File | null = null
  private signForFiles = {}
  private signLoading : boolean = false

  private filesSigned(sign) {
    if (this.fileForSign?.hasOwnProperty('uid')) {
      this.signForFiles[this.fileForSign['uid']] = sign
    }
  }

  private isExpectation: boolean = false
  // private categoryList: IDocumentTypes[] | null = []
  private typeDocument: number | null = null
  private fileList: any = []

  private form: Form = {
    code: '',
    markId: null,
    typeId: null,
    files: [],
  }

  protected rules = {
    typeId: [{required: true, message: 'Обязательное поле', trigger: 'blur'}],
    files: [{required: true, message: 'Обязательное поле', trigger: 'blur'}],
  }

  private onChangeMark(markId: number) {
    this.fetchCodes({markId, projectId: this.selectedProjectId}).then(() => {
      this.form.code = ''
    })
  }

  private changeFileList(file: File, fileList) {
    this.fileList = fileList.map((item, index) => {
      return {
        type: this.typeDocument,
        file: item,
        comment: this.fileList[index] ? this.fileList[index].comment : '',
      }
    })
    this.form.files.push(file)
  }

  get isSelectDocument() {
    return this.fileList.length !== 0;
  }

  private onChangeTypeDocument() {
    this.fileList = this.fileList.map(file => {
      return {
        type: this.typeDocument,
        file: file.file,
        comment: file.comment,
      }
    })
  }

  mounted() {
    this.fetchCodes({markId: '', projectId: this.selectedProjectId})
  }

  get files() {
    return this.fileList.map(file => file.file)
  }

  private async handleUpload(_uid) {
    const form = this.$refs.form as ElForm

    form.validate(async (isValid) => {
      if (isValid) {
        this.isExpectation = true

        try{
          const files = await this.documentUpload({projectId: this.selectedProjectId, ...this.form})
          this.$successNotify('Документы загружены успешно')
          this.$emit('success')

          try {
            this.signLoading = true
            await this.sendSigns(files)
          } catch (error) {
            console.error(error)
            this.$errorNotify('Произошла ошибка во время загрузки подписи')
          }

          this.handleCloseDialog()
          this.fileList = []
        } catch (error) { this.$errorNotify(error?.response?.data?.message) }
        finally {
          this.isExpectation = false
          this.signLoading = false
        }
      }
    })
  }

  private async sendSigns(files){
    if(!Array.isArray(files)){
      files = [files]
    }

    files = files.map((file, index) => {
      if(this.files[index].uid in this.signForFiles){
        file.sign_uid = this.files[index].uid
      }

      return file
    }).filter((file) => file.sign_uid)

    for (const file of files){
      await this.signFile(file)
    }

    this.$emit('filesSigned', files.map(file => file.id))
  }

  async signFile(file){
    if(!file.hasOwnProperty('sign_uid')) return
    await this.signUpload({sign: this.signForFiles[file.sign_uid], user_id: this.user.id, document_id: file.id})
  }

  private deleteDocument(uid) {
    const index = this.fileList.findIndex(file => file.file.uid === uid)
    delete this.signForFiles[uid]
    this.fileList = this.fileList.filter((el, i) => i != index)
    this.form.files = this.form.files.filter((el, i) => i != index)
  }

  private handleCloseDialog() {
    const form = this.$refs.form as ElForm

    this._visible = false

    form.resetFields()
  }
}
